/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import { Toast } from 'vant';
import LCFQheader from "../../LCFQheader";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, getTextLst, formatHM, formatHMT } from "@/assets/app.js";
export default {
  name: "lcfqYyqy",
  components: {
    LCFQheader
  },
  data() {
    return {
      headTit: "新建申请",
      tit: "用印移交",
      oneft: "/index",
      single: false,
      allowSelect: true,
      showUserDialog: false,
      showGroupDialog: false,
      nodeGroupVm: {},
      showYzmc: false,
      showYjrq: false,
      showJsrq: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      userID: "",
      solId: "",
      actDefId: "",
      boDefId: "",
      formKey: "",
      yzmcList: [],
      yzList: [],
      data: {
        jsbm: "",
        jsr: "",
        yjr: "",
        yzty: []
      },
      yzty: []
    };
  },
  mounted() {
    // this.initBySolInstId();
    this.yzlistInfo();
    this.cgORxj();
  },
  methods: {
    async cgORxj() {
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        let solId = this.$route.params.solId;
        var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId + "&instId=" + instId;
        this.$ajax.post(url).then(res => {
          this.solId = res.data.bpmSolution.solId;
          this.actDefId = res.data.bpmSolution.actDefId;
          this.boDefId = res.data.formModels[0].boDefId;
          this.formKey = res.data.formModels[0].formKey;
          this.data = res.data.formModels[0].jsonData;
          this.data.yzty = [];
        });
      } else {
        this.initBySolInstId();
      }
    },
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
        let jsbmId = response.data.formModels[0].jsonData.jsbm;
        let jsbmName = response.data.formModels[0].jsonData.jsbm_name;
        let jsbmList = [{
          id: jsbmId,
          text: jsbmName
        }];
        this.data.jsbm = JSON.stringify(jsbmList);
        let jsrId = response.data.formModels[0].jsonData.jsr;
        let jsrName = response.data.formModels[0].jsonData.jsr_name;
        let jsrList = [{
          id: jsrId,
          text: jsrName
        }];
        this.data.jsr = JSON.stringify(jsrList);
        let yjrId = response.data.formModels[0].jsonData.yjr;
        let yjrName = response.data.formModels[0].jsonData.yjr_name;
        let yjrList = [{
          id: yjrId,
          text: yjrName
        }];
        this.data.yjr = JSON.stringify(yjrList);
      });
    },
    yzlistInfo() {
      var yzUrl = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqyjyz.do";
      this.$ajax.post(yzUrl).then(res => {
        this.yzList = res.data.data;
        this.yzList.map(item => {
          this.yzmcList.push(item.YZMC);
        });
      });
    },
    //下拉联动
    onYzmc(value) {
      this.data.yzmc_name = value;
      this.yzList.map(item => {
        if (value == item.YZMC) {
          this.data.yzybgry = item.SQR;
          this.data.yjbm = item.SQBM;
          // this.data.yjr = item.F_YJR;
          // this.data.jsbm = item.F_JSBM;
          // this.data.jsr = item.F_JSR;
        }
      });

      this.showYzmc = false;
    },
    onYjrq(timestamp) {
      this.data.yjrq = formatHM(timestamp);
      this.showYjrq = false;
    },
    onJsrq(timestamp) {
      this.data.jsrq = formatHM(timestamp);
      this.showJsrq = false;
    },
    //选人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    selectGroupDialog(vm) {
      this.showGroupDialog = true;
      this.$refs.sysGroup.type = "group";
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        var test = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    //上传附件
    beforeRead(file) {
      // png,jpg,jpeg,bmp,gif
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/bmp' && file.type !== 'image/gif') {
        Toast('请上传 png,jpg,jpeg,bmp,gif 格式图片');
        return false;
      } else {
        if (this.data.yzty.length > 0) {
          let yztyStr = this.onFile();
          if (yztyStr.indexOf(file.name) == -1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    },
    afterRead(file) {
      let wnsc = new FormData();
      wnsc.append("from", "SELF");
      wnsc.append("types", "");
      wnsc.append("sysTreeId", "");
      wnsc.append("id", "WU_FILE_0");
      wnsc.append("name", file.file.name);
      wnsc.append("type", file.file.type);
      wnsc.append("lastModifiedDate", file.file.lastModifiedDate);
      wnsc.append("size", file.file.size);
      wnsc.append("file", file.file);
      var yztyObj;
      let wnscUrl = _baseUrl + "/sys/core/file/upload.do";
      this.$ajax.post(wnscUrl, wnsc).then(res => {
        yztyObj = {};
        yztyObj.fileId = res.data.data[0].fileId;
        yztyObj.fileName = res.data.data[0].fileName;
        yztyObj.totalBytes = res.data.data[0].totalBytes;
        this.yzty.push(yztyObj);
      });
    },
    beforeDelete(file) {
      for (let i = 0; i < this.yzty.length; i++) {
        if (file.file.name == this.yzty[i].fileName) {
          this.yzty.splice(i, 1);
        }
      }
      return true;
    },
    onFile() {
      let yztyObj = {};
      let yzty = [];
      this.data.yzty.map(item => {
        yztyObj = {};
        yztyObj.fileId = item.file.lastModified;
        yztyObj.fileName = item.file.name;
        yztyObj.totalBytes = item.file.size;
        yzty.push(yztyObj);
      });
      return JSON.stringify(yzty);
    },
    datas() {
      var yzty = JSON.stringify(this.yzty);
      var yjrq = formatHMT(this.data.yjrq);
      var jsrq = formatHMT(this.data.jsrq);
      let yjr = JSON.parse(this.data.yjr);
      let jsbm = JSON.parse(this.data.jsbm);
      let jsr = JSON.parse(this.data.jsr);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            yzmc: this.data.yzmc_name,
            yzmc_name: this.data.yzmc_name,
            yzty: yzty,
            yzybgry: this.data.yzybgry,
            yjsy: this.data.yjsy,
            yjbm: this.data.yjbm,
            yjr: yjr[0].id,
            yjr_name: yjr[0].text,
            yjrq: yjrq,
            jsbm: jsbm[0].id,
            jsbm_name: jsbm[0].text,
            jsr: jsr[0].id,
            jsr_name: jsr[0].text,
            jsrq: jsrq,
            yjym: this.data.yjym
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    onSubmit() {
      // http://192.168.1.54:8092/xzoa/bpm/core/bpmInst/saveDraft.do
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
    },
    onPreservation() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};